<template>
  <div
    id="transaction_log"
    class="card"
  >
    <b-card-header>
      <ps-filters
        v-model="filterValues"
        :filter-settings="filterSettings"
        :show-export="true"
        @search="search"
        @exportToCSV="exportToCSV"
      />
    </b-card-header>
    <div class="table-responsive mb-0">
      <table
        role="table"
        class="table b-table table-striped table-hover"
      >
        <thead
          role="rowgroup"
          class=""
        >
          <tr
            role="row"
            class=""
          >
            <th
              role="columnheader"
              aria-colindex="1"
              :aria-sort="arialSort('created_at')"
              @click="sortCol('created_at')"
            >
              DateTime
            </th>
            <th
              role="columnheader"
              aria-colindex="2"
              :aria-sort="arialSort('amount')"
              @click="sortCol('amount')"
            >
              Amount
            </th>
            <th
              role="columnheader"
              aria-colindex="3"
              :aria-sort="arialSort('type')"
              @click="sortCol('type')"
            >
              Type
            </th>
            <th
              role="columnheader"
              aria-colindex="3"
              :aria-sort="arialSort('hash')"
              @click="sortCol('hash')"
            >
              Hash
            </th>
            <th
              role="columnheader"
              aria-colindex="3"
              :aria-sort="arialSort('external_user_id')"
              @click="sortCol('external_user_id')"
            >
              External User ID
            </th>
            <th
              role="columnheader"
              aria-colindex="3"
              :aria-sort="arialSort('reconciled_transaction_id')"
              @click="sortCol('reconciled_transaction_id')"
            >
              Reconciled Transaction ID
            </th>
          </tr>
        </thead>
        <tbody role="rowgroup">
          <template v-for="(rowTr, indextr) in items">
            <tr
              :key="indextr"
              :class="rowTr.class"
            >
              <td>
                {{ rowTr.created_at }}
              </td>
              <td>
                {{ rowTr.amount }}
              </td>
              <td :class="`text-${rowTr.typeClass}`">
                {{ rowTr.type }}
              </td>
              <td>
                {{ rowTr.hash }}
              </td>
              <td>
                {{ rowTr.external_user_id }}
              </td>
              <td>
                {{ rowTr.reconciled_transaction_id }}
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <b-card-footer>
      <b-row>
        <b-col
          v-show="items.length > 0"
          cols="12"
        >
          <pagination-and-table-info
            :data="paginationData"
            @change="handleChangePage($event)"
          />
        </b-col>
        <b-col
          v-show="items.length === 0"
          cols="12"
          style="font-weight: 500; text-align: center"
        >
          No data
        </b-col>
      </b-row>
    </b-card-footer>
  </div>
</template>

<script>
import PaginationAndTableInfo from '@ps_main/custom-components/PaginationAndTableInfo.vue'
import PsFilters from '@/main/custom-components/PsFilters.vue'

export default {
  components: {
    PsFilters,
    PaginationAndTableInfo,
  },
  data() {
    return {
      url: '/api/logs/transaction-log',
      items: [],
      filterValues: {},
      filterSettings: {
        dateRange: {
          type: 'daterange',
          label: 'From - To',
          class: 'mr-2 mb-0',
          style: '',
          data: [],
          defaultValue: {
            fromDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
            toDate: new Date(),
          },
          postKey: 'dateRange',
          'label-cols': 2,
        },
        type: {
          type: 'select',
          label: 'Type',
          class: 'mr-2 mb-0',
          data: [
            { value: 0, text: 'All types' },
            { value: 1, text: 'Complete' },
            { value: 2, text: 'Screenout' },
            { value: 3, text: 'Payout' },
            { value: 4, text: 'Reconciliation' },
            { value: 5, text: 'Payout Canceled' },
            { value: 6, text: 'Other' },
          ],
          defaultData: { value: 0, text: 'All types' },
          defaultValue: 0,
          'label-cols': 3,
        },
      },
      params: {
        page: 1,
        orderBy: 'created_at',
        itemPerPage: 10,
        orderDirection: 'DESC',
      },
      pageTotal: 0,
      totalItems: 0,
      responseData: null,
    }
  },
  computed: {
    paginationData() {
      return this.laravelPaginationPages(this.responseData)
    },
  },
  created() {
    // this.search()
  },
  methods: {
    handleChangePage(page) {
      this.params.page = page
      this.search()
    },
    sortCol(fieldName) {
      if (this.params.orderBy === fieldName) {
        this.params.orderDirection = this.params.orderDirection === 'asc' ? 'desc' : 'asc'
      } else {
        this.params.orderBy = fieldName
        this.params.orderDirection = 'asc'
      }
      this.search()
    },
    arialSort(sortFieldName) {
      if (this.params.orderBy === sortFieldName) {
        return this.params.orderDirection === 'asc'
          ? 'ascending'
          : 'descending'
      }
      return 'none'
    },
    search() {
      this.showLoading()
      this.$http
        .get(`${this.url}/get`, {
          params: {
            ...this.params,
            ...this.filterValues,
          },
        })
        .then(response => {
          this.hideLoading()
          this.items = response.data.data
          this.itemsTotal = response.data.total
          this.responseData = response.data
        })
        .catch(errors => {
          this.alertError(errors.response.data)
          this.hideLoading()
        })
    },
    exportToCSV() {
      this.isBusy = true
      this.showLoading()
      this.$http
        .get(`${this.url}/export`, {
          params: {
            ...this.params,
            ...this.filterValues,
            only_file_name: true,
          },
        })
        .then(response => {
          const filename = response.data

          this.$http
            .get(`${this.url}/export`, {
              params: {
                ...this.params,
                ...this.filterValues,
              },
              responseType: 'blob',
            })
            .then(responseExport => {
              this.hideLoading()

              const url = window.URL.createObjectURL(new Blob([responseExport.data]))
              const link = document.createElement('a')
              link.href = url
              link.setAttribute('download', filename)
              document.body.appendChild(link)
              link.click()

              this.isBusy = false
            })
            .catch(errors => {
              if (typeof errors.response === 'object') {
                if (typeof errors.response.data.errors === 'object') {
                  this.alertError(errors.response.data.errors)
                } else {
                  this.alertError(errors.response.data)
                }
              } else {
                this.alertError(errors.message)
              }

              this.hideLoading()
              this.isBusy = false
            })
        })
        .catch(errors => {
          if (typeof errors.response === 'object') {
            if (typeof errors.response.data.errors === 'object') {
              this.alertError(errors.response.data.errors)
            } else {
              this.alertError(errors.response.data)
            }
          } else {
            this.alertError(errors.message)
          }

          this.hideLoading()
          this.isBusy = false
        })
    },
  },
}
</script>
